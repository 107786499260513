export const environment = {
  production: false,
  map_box_token: '',
  firebase: {
    apiKey: 'AIzaSyAzoEw6vw--C9KanqD3IpBwdA_qkZ0_tHo',
    authDomain: 'sahayak-online.firebaseapp.com',
    projectId: 'sahayak-online',
    storageBucket: 'sahayak-online.appspot.com',
    messagingSenderId: '453604346037',
    appId: '1:453604346037:web:b38e04dd80032784dd56fc',
    measurementId: 'G-F219DF5RSR',
    vapidKey:
      'BAs0sWEMsdmrwj1XICv0Z-lUSrHvNgRZ4W9TVtRXdD9G9xoZSQeeA5hbq-cs3wtI5QXsDOi1Bd7HExyK4-M7dcs',
  },
  adminEmail: 'admin@sahayakonline.com',
  stripe: {
    sk: '',
  },
};
